.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.5s ease-in-out; /* Анимация появления */
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-size: 14px;
  margin-bottom: 5px;
}

input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  border-color: #007bff;
}

.submit-btn {
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: transform 0.3s; /* Плавный переход */
}

.submit-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Увеличение при наведении */
}

@media (max-width: 600px) {
  .form-container {
    padding: 15px;
  }

  input {
    font-size: 14px;
  }

  .submit-btn {
    font-size: 14px;
    padding: 10px;
  }
}

.alert {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-radius: 5px;
  color: white;
  font-size: 1.2rem;
  opacity: 0;
  animation: fadeInOut 3s forwards;
  z-index: 1000;
}

.alert.success {
  background-color: green;
}

.alert.error {
  background-color: red;
}
@keyframes fadeInOut {
  0% {
      opacity: 0;
  }
  10% {
      opacity: 1;
  }
  90% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

.order-history-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 15px;  /* Reduced padding */
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.create-order-btn {
  padding: 8px 12px;  /* Reduced padding */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 15px;  /* Reduced margin */
  cursor: pointer;
  font-size: 14px;  /* Reduced font size */
  margin: 4px;
}

.create-order-btn:hover {
  background-color: #0056b3;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;  /* Reduced margin */
}

.order-table th, .order-table td {
  padding: 8px;  /* Reduced padding */
  border: 1px solid #ddd;
  text-align: left;
  font-size: 14px;  /* Reduced font size */
}

.order-table th {
  background-color: #f2f2f2;
}

.order-table td {
  background-color: #fff;
}

.delete-btn {
  padding: 4px 8px;  /* Reduced padding */
  background-color: red;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;  /* Reduced font size */
  margin-left: 30%;
}

.delete-btn:hover {
  background-color: darkred;
}

.alert {
  margin-bottom: 15px;  /* Reduced margin */
  padding: 8px;  /* Reduced padding */
  border-radius: 5px;
  color: white;
  font-size: 14px;  /* Reduced font size */
}

.alert.success {
  background-color: green;
}

.alert.error {
  background-color: red;
}

@media (max-width: 600px) {
  .form-container {
    padding: 15px;
  }

  input {
    font-size: 14px;
  }

  .submit-btn {
    font-size: 14px;
    padding: 10px;
  }
}

.referal-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.referal-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.referral-link {
  display: block;
  margin: 10px 0;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #555;
  font-size: 16px;
  text-decoration: none;
  overflow-wrap: break-word;
}

.copy-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0;
}

.copy-btn:hover {
  background-color: #0056b3;
}

.stats {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.stat-item {
  text-align: center;
}

.stat-item h3 {
  font-size: 20px;
  color: #007bff;
}

.stat-item p {
  font-size: 16px;
  color: #555;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 20px;
}

.profile-container {
  max-width: 400px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.profile-container h2 {
  margin-bottom: 15px;
}

.profile-container p {
  font-size: 16px;
  margin: 5px 0;
}

.loading {
  text-align: center;
  font-size: 20px;
  color: #555;
  animation: fadeIn 1s; /* Плавное появление текста загрузки */
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.tariff-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
}

.tariff-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding: 16px;
  background-color: #f7f7f7;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tariff-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.tariff-card p {
  font-size: 1rem;
  color: #666;
  margin: 5px 0 0;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 20px 0;
  flex-wrap: wrap;
}

.pagination button {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #666;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination button.active {
  background-color: #1976d2;
  color: white;
  border: none;
}

.pagination button:hover:not(.active) {
  background-color: #f5f5f5;
}

.pagination button:disabled {
  background-color: #f0f0f0;
  color: #ccc;
  cursor: not-allowed;
  border: 1px solid #ccc;
}

.pagination .range-info {
  font-size: 14px;
  color: #666;
  margin-left: 16px;
}

/* Адаптивный стиль для мобильных экранов */
@media (max-width: 768px) {
  .pagination {
      gap: 4px;
  }

  .pagination button {
      width: 32px;
      height: 32px;
      font-size: 14px;
  }
}

.select-time-btn {
  background-color: #4CAF50; /* Зеленый фон */
  border: none; /* Убираем границу */
  color: rgb(0, 0, 0); /* Белый текст */
  padding: 10px 20px; /* Отступы */
  text-align: center; /* Выравнивание текста по центру */
  text-decoration: none; /* Убираем подчеркивание */
  display: inline-block; /* Встроенный блочный элемент */
  font-size: 16px; /* Размер шрифта */
  margin: 4px 2px; /* Отступы */
  cursor: pointer; /* Курсор в виде руки */
  border-radius: 4px; /* Скругленные углы */
}

.select-time-btn:hover {
  background-color: #45a049; /* Темно-зеленый фон при наведении */
}

.busy-hour {
    background-color: #ff0015; /* Светло-красный фон */
    color: #000000; /* Темно-красный текст */
}


.loading {
  color: #666;
  font-size: 18px;
  min-height: 200px;
  align-items: center;
  justify-content: center;
  display: flex;
}