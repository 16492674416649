.amount-input {
    width: 100%;
    max-width: 500px;
    padding: 10px 15px;
    font-size: 16px;
    color: #000000;
    border: 1px solid #000;
    border-radius: 2px;
    background-color: #fff;
    outline: none;
    transition: border-color 0.3s ease;
    border-radius: 10px;
}

.amount-input:focus {
    border-color: #333;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}